var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.productItem)?_c('portal',{attrs:{"to":"body-top"}},[_c('h4',{staticClass:"font-medium-4 text-center font-weight-bolder mb-1"},[_vm._v(" "+_vm._s(_vm.$t("Asset"))+" "+_vm._s(_vm.productItem.sku)+" "+_vm._s(("— " + (_vm.productItem.name ? _vm.productItem.name : "— ")))+" ")])]):_vm._e(),_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumnsCatalog,"table-config-options":{
      endpoint: (_vm.MODULE_NAME + "/getInventoryCatalogAudit"),
      data: {
        id: _vm.id,
        inv_id: this.$route.params.inv_id
      }
    }},scopedSlots:_vm._u([{key:"cell(event)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.event)+" ")]}},{key:"cell(date)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.convertTZ(data.item.updated_at))+" ")]}},{key:"cell(user)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.user ? data.item.user.firstname : '')+" "+_vm._s(data.item.user ? data.item.user.lastname : '')+" ")]}}])}),_c('portal',{attrs:{"to":"body-footer"}},[_c('div',{staticClass:"d-flex mt-2 pb-1 justify-content-between"},[_c('div',[_c('b-button',{staticClass:"cancelBtn font-medium-1 font-weight-bolder",attrs:{"variant":"outline-primary"},on:{"click":_vm.back}},[_vm._v(" "+_vm._s(_vm.$t("Back to View"))+" ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }