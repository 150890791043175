<template>
  <div>
    <portal
      v-if="productItem"
      to="body-top"
    >
      <h4 class="font-medium-4 text-center font-weight-bolder mb-1">
        {{ $t("Asset") }}
        {{ productItem.sku }}
        {{ `— ${productItem.name ? productItem.name : "— "}` }}
      </h4>
    </portal>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumnsCatalog"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/getInventoryCatalogAudit`,
        data: {
          id,
          inv_id: this.$route.params.inv_id
        }
      }"
    >
      <template #cell(event)="{ data }">
        {{ data.item.event }}
      </template>
      <template #cell(date)="{ data }">
        {{ convertTZ(data.item.updated_at) }}
      </template>
      <template #cell(user)="{ data }">
        {{ data.item.user ? data.item.user.firstname : '' }}
        {{ data.item.user ? data.item.user.lastname : '' }}
      </template>
    </l-table>
    <portal to="body-footer">
      <div class="d-flex mt-2 pb-1 justify-content-between">
        <div>
          <b-button
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
            @click="back"
          >
            {{ $t("Back to View") }}
          </b-button>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import LTable from '@/views/components/LTable/LTable.vue'
import { BButton } from 'bootstrap-vue'
import config from './config'

export default {
  name: 'AuditCatalog',
  components: {
    LTable,
    BButton,
  },
  data() {
    return {
      id: this.$route.params.id,
    }
  },
  computed: {
    productItem() {
      // console.log(this.$route.params)
      const item = this.$store.state[this.MODULE_NAME].product
      return item
        ? {
          name: item.name,
          sku: item.sku,
        }
        : null
    },
  },
  async created() {
    await this.$store
      .dispatch(`${this.MODULE_NAME}/getStockProduct`, {
        id: this.$route.params.id,
      })
      .then(res => {
        const { data } = res.data

        this.$store.commit(`${this.MODULE_NAME}/GET_PRODUCT`, data)
      })
  },
  methods: {
    back() {
      this.$router.back()
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'

    const { tableColumnsCatalog, ACCESS_ABILITY_INVENTORY } = config()
    return {
      tableColumnsCatalog,
      MODULE_NAME,
      ACCESS_ABILITY_INVENTORY,
    }
  },
}
</script>
